import React from "react";
import { graphql } from "gatsby";
import get from 'lodash.get';

import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import WorkFilters from '../components/work-filters';
import siteContent from '../config/site-content';
import PortfolioListingComponent from '../components/portfolio-listing-component';

const PortfolioPage = (props) => {
    const {data} = props;

    const projects     = get(data, 'allWordpressWpPortfolio.edges', []);
    const projectTypes = get(data, 'allWordpressWpProjectTypes.edges', []);
    const siteTitle  = get(data, 'site.siteMetadata.title', '');

    const pageTitle  = get(siteContent, 'portfolio.pageTitle', '');
    const heading    = get(siteContent, 'portfolio.heading', '');
    const subHeading = get(siteContent, 'portfolio.subheading', '');

    const allProjectTypes = projectTypes.reduce((acc, {node: curr}) => {
        acc[curr.wordpress_id] = curr.name;
        return acc;
    }, {});

    return (
        <Layout>
            <PageHeader
                heading={heading}
                subHeading={subHeading}
                pageTitle={`${pageTitle} | ${siteTitle}`}
            />
            <WorkFilters projectTypes={allProjectTypes} />
            <PortfolioListingComponent
                types={allProjectTypes}
                projects={projects}
            />
        </Layout>
    );
};

export default PortfolioPage;

export const pageQuery = graphql`
    query PortfolioQuery {
        site {
            siteMetadata {
                title
            }
        }
        allWordpressWpPortfolio {
            edges {
                node {
                    project_types
                    title
                    id
                    slug
                    acf {
                        project_subheading
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 605){
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
        allWordpressWpProjectTypes {
            edges {
                node {
                    name
                    wordpress_id
                }
            }
        }
    }
`;

